body {
  font-family: var(--font-family-base);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

a {
  color: var(--accent-color-primary);
  text-decoration: none;
  transition: color var(--transition-base);

  &:hover {
    color: var(--accent-color-secondary);
  }
}