.modal {
  display: flex !important;
  justify-content: center;
  align-items: center; /* Zentriert das Modal vertikal und horizontal */
  color: var(--text-color);

  .modal-dialog {
    height: 100vh;
    max-width: 600px; /* Standardbreite des Modals */
    width: 100%; /* Responsives Verhalten */
    margin: 0 auto; /* Zentrierung */
    transition: var(--transition-base); /* Sanfte Animation bei Erscheinen */
  }

  .modal-content {
    background-color: var(--background-color); /* Heller Hintergrund */
    color: var(--text-color); /* Haupttextfarbe */
    border-radius: var(--border-radius); /* Abgerundete Ecken */
    box-shadow: var(--box-shadow); /* Leichter Schatten für Tiefe */
    border: 1px solid var(--border-color); /* Dezente Umrandung */
  }

  .modal-header {
    background: var(--background-color-secondary); /* Kontrastreicher Header */
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color); /* Trennlinie zum Body */
    padding: var(--padding-base);

    .modal-title {
      font-size: var(--font-size-large);
    }

    .btn-close {
      color: var(--text-color-secondary);
      transition: color var(--transition-base);

      &:hover {
        color: var(--accent-color-primary);
      }
    }
  }

  .modal-body {
    background: var(--background-color); /* Hauptbereich mit hellem Grau */
    color: var(--text-color); /* Lesbarer Text */
    padding: var(--padding-base);
  }

  .modal-footer {
    background: var(--background-color-secondary); /* Abgesetzter Footer */
    color: var(--text-color);
    border-top: 1px solid var(--border-color); /* Trennlinie zum Body */
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    .btn {
      padding: 0.5rem 1.5rem;
      font-size: var(--font-size-base);
      border-radius: var(--border-radius);
    }

    .btn-primary {
      background-color: var(--accent-color-primary);
      color: #fff;
      border: none;

      &:hover {
        background-color: var(--accent-color-secondary);
      }
    }

    .btn-secondary {
      background-color: var(--background-color-layout);
      color: var(--text-color-secondary);
      border: 1px solid var(--border-color);

      &:hover {
        background-color: var(--background-color-secondary);
      }
    }
  }

  input,
  textarea {
    background: var(--background-color-secondary);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 0.5rem;
    border-radius: var(--border-radius);
    width: 100%;
    transition: border-color var(--transition-base);

    &:focus {
      outline: none;
      border-color: var(--accent-color-primary);
    }
  }
}
