:root {
  // Grundfarben (Monochrom)
  --background-color: #f9f9f9; /* Fast Weiß */
  --background-color-secondary: #eaeaea; /* Sehr helles Grau */
  --background-color-layout: #dcdcdc; /* Subtileres Grau für Abgrenzungen */
  --text-color: #2c2c2c; /* Dunkles Grau für Texte */
  --text-color-secondary: #6c6c6c; /* Mittelgrau für sekundären Text */
  --border-color: #cccccc; /* Leichte Trennlinien */

  // Akzentfarben
  --accent-color-primary: #4a7fe2; /* Hellblau als Hauptakzent */
  --accent-color-secondary: #8abdf8; /* Leichteres Hellblau für weiche Akzente */

  // Typografie
  --font-family-base: 'Poppins', sans-serif;
  --font-size-base: 16px;
  --font-size-large: 20px;
  --font-size-small: 14px;

  // Abstände und Größen
  --border-radius: 8px; /* Abgerundete Ecken */
  --padding-base: 1rem;
  --margin-base: 1rem;
  --container-max-width: 1200px;

  // Effekte
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Leichter Schatten für Tiefe */
  --transition-base: 0.3s ease; /* Sanfte Animationen */

  // Layout
  --header-height: 80px; /* Höhe des Headers */
  --footer-height: 100px; /* Höhe des Footers */
}
