#DashboardProfile {
  color: var(--text-color);
  background-color: var(--background-color);
  padding: var(--padding-base);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);

  .profile-heading {
    text-align: center;
    font-size: var(--font-size-large);
    margin-bottom: var(--margin-base);
    color: var(--accent-color-primary);
  }

  .profile-card {
    display: flex;
    flex-wrap: wrap;
    gap: var(--margin-base);
    padding: var(--padding-base);
    background-color: var(--background-color-secondary);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);

    .profile-picture {
      flex: 1 1 200px;
      text-align: center;

      .profileImage {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid var(--accent-color-primary);
        box-shadow: var(--box-shadow);
      }

      .placeholder-image {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--background-color-layout);
        color: var(--text-color-secondary);
        border: 2px dashed var(--border-color);
      }
    }

    .profile-info {
      flex: 2 1 400px;

      p {
        font-size: var(--font-size-base);
        margin-bottom: 0.75rem;

        strong {
          color: var(--accent-color-primary);
        }
      }

      .profile-link {
        color: var(--accent-color-primary);
        text-decoration: none;
        transition: color var(--transition-base);

        &:hover {
          color: var(--accent-color-secondary);
          text-decoration: underline;
        }
      }
    }
  }
}