@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px; /* Baseline-Typografie */
  color: var(--text-color);
  line-height: 1.6; /* Standard-Zeilenhöhe für Lesbarkeit */
}

p {
  font-size: calc(0.4vw + 0.8rem);
  font-weight: 300; /* Weniger Gewicht für Fließtext */
  line-height: 1.8;
  margin-bottom: 1rem;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Work Sans', sans-serif;
  margin-bottom: 0.8rem;
}

h1 {
  font-size: calc(1.2vw + 1.5rem);
  font-weight: 700;
  line-height: 1.2;
  color: var(--text-color);
}

h2 {
  font-size: calc(1vw + 1.2rem);
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-color);
}

h3 {
  font-size: calc(0.8vw + 1rem);
  font-weight: 500;
  line-height: 1.4;
  color: var(--text-color);
}

h4 {
  font-size: calc(0.6vw + 0.9rem);
  font-weight: 500;
  color: var(--text-color);
}

h5 {
  font-size: calc(0.5vw + 0.8rem);
  font-weight: 400;
  color: var(--text-color-secondary);
}

h6 {
  font-size: calc(0.4vw + 0.7rem);
  font-weight: 400;
  color: var(--text-color-secondary);
}

a {
  font-size: calc(0.4vw + 0.8rem);
  font-weight: 500;
  color: var(--text-link-color);
  text-decoration: none;
  transition: color var(--transition-base);

  &:hover {
    color: var(--accent-color-primary);
    text-decoration: underline;
  }

  * {
    color: inherit; /* Vererbt die Farbe an verschachtelte Elemente */
  }
}

i {
  font-size: 1.5rem;
  color: var(--text-color-secondary);
  filter: drop-shadow(0 0 0.05rem var(--accent-color-primary));
  transition: filter var(--transition-base);

  &:hover {
    filter: brightness(125%);
  }
}

.text-hover-highlighted {
  color: var(--text-color);
  font-weight: 600;
  transition: filter var(--transition-base);

  &:hover {
    filter: brightness(125%);
  }
}

.btn {
  font-size: calc(0.4vw + 0.8rem);
  font-weight: 500;
  border-radius: var(--border-radius);
  transition: background-color var(--transition-base), color var(--transition-base);

  --bs-btn-color: var(--text-color-secondary);
  --bs-btn-hover-color: var(--text-color);
  --bs-btn-hover-bg: var(--accent-color-primary);
}

blockquote {
  font-size: calc(0.6vw + 1rem);
  font-weight: 300;
  font-style: italic;
  padding-left: 1rem;
  border-left: 4px solid var(--accent-color-primary);
  color: var(--text-color-secondary);
  margin: 1rem 0;
}
