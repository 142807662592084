.footer {
  height: var(--footer-height); /* Höhe des Footers */
  background-color: var(--background-color);
  color: var(--text-color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  text-align: center;
  font-size: calc(0.4vw + 0.8rem);
  border-top: 1px solid var(--border-color);

  .footer-content {
    max-width: var(--container-max-width);
    margin: 0 auto;

    p {
      margin: 0.5rem 0;

      strong {
        color: var(--accent-color-primary);
      }
    }
  }
}
