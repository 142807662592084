// Home Page
#Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - 12.5rem);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 400px;
    * {
      margin: 2px;
    }
    label *{
      margin: 2px;
      border-radius: 7.5px;
      padding: 3px;
    }
  }
}
