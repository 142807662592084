#Dashboard {
  font-family: var(--font-family-base);
  font-size: var(--font-size-base);
  color: var(--text-color);
  display: flex;

  .sidebar {
    background-color: var(--background-color-secondary);
    color: var(--text-color);
    width: 250px;
    padding: 1rem;
    height: 100vh;
    top: var(--header-height);
    transition: transform 0.3s ease, width 0.3s ease;
    overflow-x: hidden;
    position: fixed;

    &.sidebar-closed {
      width: 80px;

      .content h1 {
        display: none;
      }

      .nav-link span {
        display: none;
      }
    }

    .toggle-btn {
      position: absolute;
      bottom: 110px;
      left: 20px;
      width: 40px;
      height: 40px;
      background-color: var(--primary-color);
      color: var(--text-color);
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      transition: background-color 0.2s ease, transform 0.2s ease;
      z-index: 1000;

      &:hover {
        background-color: var(--secondary-color);
        transform: scale(1.1);
      }
    }

    .nav {
      margin-top: 1rem;
      padding: 0;

      .nav-item {
        margin-bottom: 1rem;

        .nav-link {
          color: var(--text-link-color);
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          transition: background-color 0.3s ease, color 0.3s ease;

          &:hover {
            background-color: var(--background-color-layout);
            color: var(--primary-color);
          }

          i {
            font-size: 1.2rem;
          }
        }
      }

      .admin-section {
        margin-top: 2rem;
        border-top: 1px solid var(--border-color);
        padding-top: 1rem;

        h2 {
          color: var(--secondary-color);
        }
      }
    }
  }

  .container-fluid {
    margin-left: 250px;
    flex-grow: 1;
    background-color: var(--background-color);
    padding: 2rem;

    &.content-shifted {
      margin-left: 80px;
    }
  }
}