.header {
  height: var(--header-height); /* Höhe des Headers */
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  position: fixed;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-shadow: var(--box-shadow);
  border-bottom: 1px solid var(--border-color);

  .logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;

    .logo {
      height: calc(var(--header-height) / 2); /* Dynamische Höhe des Logos */
      margin-right: 10px;
    }

    .site-title {
      font-size: calc(0.5vw + 1rem);
      font-weight: 700;
      margin-bottom: 0;
      color: var(--accent-color-primary);
    }
  }

  .header-right {
    display: flex;
    gap: 1rem;

    .nav-link {
      color: var(--text-link-color);
      font-size: calc(0.4vw + 0.8rem);
      text-decoration: none;
      transition: color var(--transition-base);

      &:hover {
        color: var(--accent-color-primary);
      }
    }
  }
}
